<template>
  <f-page :title="$t('labels.fileUpload')">
    <f-card>
      <f-row>
        <f-col md="12">
          <f-upload
            accept="*/*"
            drag-drop
            multiple
            :placeholder="$t('labels.fileUploadPlaceholder')"
            @input="onAddAttachments"
          />
        </f-col>

        <f-col md="12">
          <f-datatable
            :columns="attachmentColumns"
            :data="attachmentFiles"
            :features="{
              delete: true,
              pagination: false,
              fullscreen: false,
              showColumns: false
            }"
            @delete="deleteAttachment"
          />
        </f-col>
        <f-col md="12">
          <f-button
            class="float-right mt-3"
            icon="paper-plane-o"
            :label="$t('labels.saveAndSend')"
            variant="info"
            @click="saveAndSend"
          />
        </f-col>
      </f-row>
    </f-card>
  </f-page>
</template>

<script>
export default {
  data () {
    return {
      attachmentFiles: [],
      attachmentColumns: []
    }
  },
  created () {
    this.attachmentColumns = [
      {
        field: 'name',
        title: this.$t('labels.fileName')
      }
    ]
  },
  methods: {
    onAddAttachments (files) {
      files.forEach(file => {
        this.attachmentFiles.push(file)
      })
    },
    deleteAttachment (file) {
      this.attachmentFiles = this.attachmentFiles.filter(
        f => f.name !== file.name
      )
    },
    async saveAndSend () {
      const formData = new FormData()

      this.attachmentFiles.forEach(file => {
        formData.append('files', file)
      })

      await this.$http.put('/companyApp/sendAttachments', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      this.$flex.notification.success(this.$t('messages.fileUploadSuccess'))
      this.attachmentFiles = []
    }
  }
}
</script>
